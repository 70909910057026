<template>
  <l-table-container>
    <template v-slot:search>
      <a-form ref="formRef" :model="search.data" :labelCol="{ style: { width: '100px' } }">
        <l-search-container @search="onSearch" @reset="onReset">
          <a-col v-bind="searchCol">
            <a-form-item label="关联者游戏ID" name="relationMaskId">
              <a-input v-model:value="search.data.relationMaskId" placeholder="请输入关联者游戏ID" allowClear> </a-input>
            </a-form-item>
          </a-col>
        </l-search-container>
      </a-form>
    </template>

    <a-spin :spinning="loading">
      <a-list :grid="{ gutter: 10, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 3, xxxl: 3 }" :data-source="data">
        <template #renderItem="{ item }">
          <a-list-item>
            <a-card :title="getLabelByValue(clubHpChangeTypes, item.changeType)" hoverable :body-style="{ padding: '5px 10px' }" :head-style="{ padding: '5px 10px' }">
              <a-descriptions title="" size="small" :label-style="{ fontWeight: 'bold' }">
                <a-descriptions-item label="游戏类型">{{ getLabelByValue(gameTypes, item.gameType) }}</a-descriptions-item>
                <a-descriptions-item label="俱乐部">{{ item.clubId }} -- {{ item.clubName }}</a-descriptions-item>
                <a-descriptions-item label="成员">{{ item.memberMaskId }} -- {{ item.memberName }}</a-descriptions-item>
                <a-descriptions-item label="变化情况">
                  {{ item.beforeHp }}
                  <span v-show="item.changeHp > 0" class="l-font-success l-bold"> + {{ item.changeHp }} </span>
                  <span v-show="item.changeHp <= 0" class="l-font-danger l-bold"> {{ item.changeHp }} </span>
                  = {{ item.afterHp }}
                </a-descriptions-item>
                <a-descriptions-item label="关联者">{{ item.relationMaskId }} -- {{ item.relationName }}</a-descriptions-item>
                <a-descriptions-item label="备注">{{ item.remak }}</a-descriptions-item>
                <a-descriptions-item label="变化时间">{{ item.createTime }}</a-descriptions-item>
              </a-descriptions>
            </a-card>
          </a-list-item>
        </template>
      </a-list>

      <div style="text-align: right">
        <a-pagination v-model:current="pagination.current" size="small" v-model:page-size="pagination.pageSize" :total="pagination.total" :show-total="pagination.showTotal" @change="handleTableChange" />
      </div>
    </a-spin>

    <!-- <l-table @reload="onSearch" :columns="columns" :data-source="data" :pagination="pagination" @change="handleTableChange" row-key="index" :loading="loading">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'changeHp'">
          <span v-show="record.changeHp > 0" class="l-font-success l-bold"> +{{ record.changeHp }} </span>
          <span v-show="record.changeHp <= 0" class="l-font-danger l-bold"> {{ record.changeHp }} </span>
        </template>

        <template v-if="column.dataIndex === 'gameType'">{{ getLabelByValue(gameTypes, record.gameType) }}</template>

        <template v-if="column.dataIndex === 'changeType'">{{ getLabelByValue(clubHpChangeTypes, record.changeType) }}</template>

        <template v-if="column.dataIndex === 'memberIcon'">
          <a-avatar :src="record.memberIcon" size="30" />
        </template>

        <template v-if="column.dataIndex === 'relationIcon'">
          <a-avatar :src="record.relationIcon" size="30" />
        </template>
      </template>
    </l-table> -->
  </l-table-container>
</template>

<script lang="ts">
import http from "@/common/http";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import { getPageOffset } from "@/common/utils";
import { clubHpChangeTypes, clubMemberIdentitys, gameTypes, getLabelByValue } from "@/common/head";

export default defineComponent({
  props: {
    detailsRow: {
      type: Object,
      required: true,
    },
  },
  components: {
    // Edit,
  },
  setup(props) {
    const columns = ref([
      {
        title: "序号",
        width: 100,
        dataIndex: "index",
        fixed: "left",
      },

      {
        title: "俱乐部ID",
        width: 100,
        dataIndex: "clubId",
        ellipsis: true,
      },

      {
        title: "俱乐部名称",
        width: 180,
        dataIndex: "clubName",
        ellipsis: true,
      },

      {
        title: "游戏类型",
        width: 140,
        dataIndex: "gameType",
        ellipsis: true,
      },

      {
        title: "邀请码<房间号>",
        width: 140,
        dataIndex: "roomCode",
        ellipsis: true,
      },

      {
        title: "变化类型",
        width: 140,
        dataIndex: "changeType",
        ellipsis: true,
      },

      {
        title: "变化前体力",
        width: 140,
        dataIndex: "beforeHp",
        ellipsis: true,
      },

      {
        title: "变化的体力",
        width: 140,
        dataIndex: "changeHp",
        ellipsis: true,
      },

      {
        title: "变化后体力",
        width: 140,
        dataIndex: "afterHp",
        ellipsis: true,
      },

      {
        title: "成员userID",
        width: 150,
        dataIndex: "memberId",
        ellipsis: true,
      },

      {
        title: "成员游戏ID",
        width: 150,
        dataIndex: "memberMaskId",
        ellipsis: true,
      },

      {
        title: "成员昵称",
        width: 150,
        dataIndex: "memberName",
        ellipsis: true,
      },

      {
        title: "成员头像",
        width: 100,
        dataIndex: "memberIcon",
        ellipsis: true,
      },

      {
        title: "关联者userID",
        width: 150,
        dataIndex: "relationId",
        ellipsis: true,
      },

      {
        title: "关联者游戏ID",
        width: 150,
        dataIndex: "relationMaskId",
        ellipsis: true,
      },

      {
        title: "关联者昵称",
        width: 150,
        dataIndex: "relationName",
        ellipsis: true,
      },

      {
        title: "关联者头像",
        width: 150,
        dataIndex: "relationIcon",
        ellipsis: true,
      },

      {
        title: "备注",
        width: 160,
        dataIndex: "remark",
        fixed: "right",
      },

      {
        title: "变化时间",
        width: 180,
        dataIndex: "createTime",
        fixed: "right",
      },
    ]);

    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        relationMaskId: "",
      },

      //点击搜索后的搜索数据
      relData: {},
    });

    //表格加载中
    const loading = ref(false);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const formRef = ref();

    //获取列表
    const getList = async () => {
      try {
        loading.value = true;
        const result = await http.get("gm_club_income_statistics/details", {
          page: pagination.current,
          page_size: pagination.pageSize,
          date: props.detailsRow.date,
          clubId: props.detailsRow.clubId,
          memberId: props.detailsRow.memberId,
          ...search.relData,
        });

        let offset = getPageOffset(pagination.current!, pagination.pageSize!);
        data.value = result.data.data.map((item: any) => {
          item.index = ++offset;
          return item;
        });
        pagination.total = result.data.count;

        loading.value = false;
      } catch (e) {
        loading.value = false;
      }
    };

    //监听页码切换
    // const handleTableChange = (pag: PaginationProps) => {
    //   pagination.current = pag?.current;
    //   pagination.pageSize = pag?.pageSize;
    //   getList();
    // };

    const handleTableChange = (pag: number, pageSize: number) => {
      pagination.current = pag;
      pagination.pageSize = pageSize;
      getList();
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;

      data.value = [];
      pagination.total = 0;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      onSearch();
    };

    onMounted(() => {
      getList();
    });

    return {
      data,
      columns,
      search,
      pagination,
      formRef,
      loading,
      handleTableChange,
      onSearch,
      onReset,
      searchCol,
      getLabelByValue,
      clubMemberIdentitys,
      gameTypes,
      clubHpChangeTypes,
    };
  },
});
</script>
