
import http from "@/common/http";
import { defineComponent, nextTick, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import { getPageOffset } from "@/common/utils";
import { clubMemberIdentitys, getLabelByValue } from "@/common/head";
import Details from "./Details.vue";

export default defineComponent({
  components: {
    Details,
    // Edit,
  },
  setup() {
    const columns = ref([
      {
        title: "序号",
        width: 70,
        dataIndex: "index",
        // fixed: "left",
      },

      {
        title: "统计日期",
        width: 110,
        dataIndex: "date",
        ellipsis: true,
        // fixed: "left",
      },

      {
        title: "收益",
        width: 140,
        dataIndex: "totalIncome",
        ellipsis: true,
        resizable: true,
      },

      // {
      //   title: "俱乐部ID",
      //   width: 140,
      //   dataIndex: "clubId",
      //   ellipsis: true,
      // },

      {
        title: "成员游戏ID",
        width: 110,
        dataIndex: "memberMaskId",
        ellipsis: true,
        resizable: true,
      },

      {
        title: "成员userId",
        width: 110,
        dataIndex: "memberId",
        ellipsis: true,
        resizable: true,
      },

      {
        title: "成员昵称",
        width: 140,
        dataIndex: "memberName",
        ellipsis: true,
        resizable: true,
      },

      {
        title: "成员身份",
        width: 90,
        dataIndex: "memberIdentity",
        ellipsis: true,
        resizable: true,
      },

      {
        title: "操作",
        align: "center",
        key: "action",
        dataIndex: "action",
        fixed: "right",
        width: 100,
      },

      // {
      //   title: "创建时间",
      //   width: 180,
      //   dataIndex: "createdAt",
      //   ellipsis: true,
      // },

      // {
      //   title: "修改时间",
      //   width: 180,
      //   dataIndex: "updatedAt",
      //   ellipsis: true,
      // },
    ]);

    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        clubId: "",
        memberMaskId: "",
        startTime: undefined,
        endTime: undefined,
      },

      //点击搜索后的搜索数据
      relData: {
        clubId: "",
      },
    });

    //表格加载中
    const loading = ref(false);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const formRef = ref();

    //查看详情
    const detailsRow = ref<any>({});
    const detailsVisible = ref(false);

    //获取列表
    const getList = async () => {
      try {
        loading.value = true;
        const result = await http.get("gm_club_income_statistics", {
          page: pagination.current,
          page_size: pagination.pageSize,
          ...search.relData,
        });

        let offset = getPageOffset(pagination.current!, pagination.pageSize!);
        data.value = result.data.data.map((item: any) => {
          item.index = ++offset;
          return item;
        });
        pagination.total = result.data.count;

        loading.value = false;
      } catch (e) {
        loading.value = false;
      }
    };

    //监听页码切换
    // const handleTableChange = (pag: PaginationProps) => {
    //   pagination.current = pag?.current;
    //   pagination.pageSize = pag?.pageSize;
    //   getList();
    // };

    const handleTableChange = (pag: number, pageSize: number) => {
      pagination.current = pag;
      pagination.pageSize = pageSize;
      getList();
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;

      data.value = [];
      pagination.total = 0;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      search.data.startTime = undefined;
      search.data.endTime = undefined;
      onSearch();

      data.value = [];
      pagination.total = 0;
    };

    //显示详情
    const showDetails = (row: any) => {
      detailsRow.value = row;
      nextTick(() => {
        detailsVisible.value = true;
      });
    };

    onMounted(() => {
      // getList();
    });

    return {
      data,
      columns,
      search,
      pagination,
      formRef,
      loading,
      handleTableChange,
      onSearch,
      onReset,
      searchCol,
      getLabelByValue,
      clubMemberIdentitys,
      showDetails,
      detailsVisible,
      detailsRow,
    };
  },
});
