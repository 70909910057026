<template>
  <l-table-container>
    <template v-slot:search>
      <a-form ref="formRef" :model="search.data" :labelCol="{ style: { width: '100px' } }">
        <l-search-container @search="onSearch" @reset="onReset">
          <a-col v-bind="searchCol">
            <a-form-item label="俱乐部ID" name="clubId">
              <a-input v-model:value="search.data.clubId" placeholder="请输入俱乐部ID" allowClear> </a-input>
            </a-form-item>
          </a-col>
          <a-col v-bind="searchCol">
            <a-form-item label="成员游戏ID" name="memberMaskId">
              <a-input v-model:value="search.data.memberMaskId" placeholder="请输入玩家游戏ID" allowClear> </a-input>
            </a-form-item>
          </a-col>
          <a-col v-bind="searchCol">
            <a-form-item label="统计日期">
              <a-row type="flex" :gutter="10" :wrap="false">
                <a-col :flex="1">
                  <a-date-picker v-model:value="search.data.startTime" inputReadOnly placeholder="开始日期" value-format="YYYY-MM-DD" style="display: block" />
                </a-col>

                <a-col flex="20px"> <div style="margin-top: 5px">~</div> </a-col>

                <a-col :flex="1">
                  <a-date-picker v-model:value="search.data.endTime" inputReadOnly placeholder="结束日期" value-format="YYYY-MM-DD" style="display: block" />
                </a-col>
              </a-row>
            </a-form-item>
          </a-col>
        </l-search-container>
      </a-form>
    </template>

    <a-spin :spinning="loading">
      <a-list :grid="{ gutter: 10, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 3, xxxl: 3 }" :data-source="data">
        <template #renderItem="{ item }">
          <a-list-item>
            <a-card :title="item.date" hoverable :body-style="{ padding: '5px 10px' }" :head-style="{ padding: '5px 10px' }">
              <a-descriptions title="" size="small" :label-style="{ fontWeight: 'bold' }">
                <a-descriptions-item label="收益">{{ item.totalIncome }}</a-descriptions-item>
                <a-descriptions-item label="成员">{{ item.memberMaskId }} -- {{ item.memberName }}</a-descriptions-item>
                <a-descriptions-item label="成员身份">{{ getLabelByValue(clubMemberIdentitys, item.memberIdentity) }}</a-descriptions-item>
              </a-descriptions>

              <template #actions>
                <l-auth-text-button auth="gm_club_income_statistics_btn" type="primary" primary @click="showDetails(item)">
                  <InfoCircleOutlined />
                  详情
                </l-auth-text-button>
              </template>
            </a-card>
          </a-list-item>
        </template>
      </a-list>

      <div style="text-align: right">
        <a-pagination v-model:current="pagination.current" size="small" v-model:page-size="pagination.pageSize" :total="pagination.total" :show-total="pagination.showTotal" @change="handleTableChange" />
      </div>
    </a-spin>

    <!-- <l-table @reload="onSearch" :columns="columns" :data-source="data" :pagination="pagination" @change="handleTableChange" row-key="index" :loading="loading">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'memberIdentity'">{{ getLabelByValue(clubMemberIdentitys, record.memberIdentity) }}</template>
        <template v-if="column.dataIndex === 'action'">
          <l-auth-text-button auth="gm_club_income_statistics_btn" type="primary" primary @click="showDetails(record)">
            <InfoCircleOutlined />
            详情
          </l-auth-text-button>
        </template>
      </template>
    </l-table> -->
  </l-table-container>

  <l-drawer :title="'详情'" :width="'90%'" v-model:visible="detailsVisible" maskClosable mask :body-style="{ paddingBottom: '80px' }">
    <Details ref="edit_ref" @close="detailsVisible = false" :details-row="detailsRow" />
  </l-drawer>
</template>

<script lang="ts">
import http from "@/common/http";
import { defineComponent, nextTick, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import { getPageOffset } from "@/common/utils";
import { clubMemberIdentitys, getLabelByValue } from "@/common/head";
import Details from "./Details.vue";

export default defineComponent({
  components: {
    Details,
    // Edit,
  },
  setup() {
    const columns = ref([
      {
        title: "序号",
        width: 70,
        dataIndex: "index",
        // fixed: "left",
      },

      {
        title: "统计日期",
        width: 110,
        dataIndex: "date",
        ellipsis: true,
        // fixed: "left",
      },

      {
        title: "收益",
        width: 140,
        dataIndex: "totalIncome",
        ellipsis: true,
        resizable: true,
      },

      // {
      //   title: "俱乐部ID",
      //   width: 140,
      //   dataIndex: "clubId",
      //   ellipsis: true,
      // },

      {
        title: "成员游戏ID",
        width: 110,
        dataIndex: "memberMaskId",
        ellipsis: true,
        resizable: true,
      },

      {
        title: "成员userId",
        width: 110,
        dataIndex: "memberId",
        ellipsis: true,
        resizable: true,
      },

      {
        title: "成员昵称",
        width: 140,
        dataIndex: "memberName",
        ellipsis: true,
        resizable: true,
      },

      {
        title: "成员身份",
        width: 90,
        dataIndex: "memberIdentity",
        ellipsis: true,
        resizable: true,
      },

      {
        title: "操作",
        align: "center",
        key: "action",
        dataIndex: "action",
        fixed: "right",
        width: 100,
      },

      // {
      //   title: "创建时间",
      //   width: 180,
      //   dataIndex: "createdAt",
      //   ellipsis: true,
      // },

      // {
      //   title: "修改时间",
      //   width: 180,
      //   dataIndex: "updatedAt",
      //   ellipsis: true,
      // },
    ]);

    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        clubId: "",
        memberMaskId: "",
        startTime: undefined,
        endTime: undefined,
      },

      //点击搜索后的搜索数据
      relData: {
        clubId: "",
      },
    });

    //表格加载中
    const loading = ref(false);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const formRef = ref();

    //查看详情
    const detailsRow = ref<any>({});
    const detailsVisible = ref(false);

    //获取列表
    const getList = async () => {
      try {
        loading.value = true;
        const result = await http.get("gm_club_income_statistics", {
          page: pagination.current,
          page_size: pagination.pageSize,
          ...search.relData,
        });

        let offset = getPageOffset(pagination.current!, pagination.pageSize!);
        data.value = result.data.data.map((item: any) => {
          item.index = ++offset;
          return item;
        });
        pagination.total = result.data.count;

        loading.value = false;
      } catch (e) {
        loading.value = false;
      }
    };

    //监听页码切换
    // const handleTableChange = (pag: PaginationProps) => {
    //   pagination.current = pag?.current;
    //   pagination.pageSize = pag?.pageSize;
    //   getList();
    // };

    const handleTableChange = (pag: number, pageSize: number) => {
      pagination.current = pag;
      pagination.pageSize = pageSize;
      getList();
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;

      data.value = [];
      pagination.total = 0;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      search.data.startTime = undefined;
      search.data.endTime = undefined;
      onSearch();

      data.value = [];
      pagination.total = 0;
    };

    //显示详情
    const showDetails = (row: any) => {
      detailsRow.value = row;
      nextTick(() => {
        detailsVisible.value = true;
      });
    };

    onMounted(() => {
      // getList();
    });

    return {
      data,
      columns,
      search,
      pagination,
      formRef,
      loading,
      handleTableChange,
      onSearch,
      onReset,
      searchCol,
      getLabelByValue,
      clubMemberIdentitys,
      showDetails,
      detailsVisible,
      detailsRow,
    };
  },
});
</script>
